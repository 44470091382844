.page-sign {
  min-height: 100vh;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0C2B50;

  @include media-breakpoint-up(sm) { background-color: #0C2B50; }

  &.d-block {
    @include gradient-x($primary, darken($primary, 15%), 40%);

    .row { min-height: inherit; }

    .col-wrapper {
      min-height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #506fd9;
    }

    .card-sign {
      border-radius: 20;
      box-shadow: none;
    }
  }

  .header-logo { font-size: 28px; }
}

.card-sign {
  width: 100%;
  border-width: 0;
  border-radius: 0;

  @media (min-width: 400px) { width: 590px; }

  @include media-breakpoint-up(sm) {
    border-radius: 10px;
    @include shadow-smooth($secondary);
  }

  .card-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 30px 30px 0;
  }

  .card-title {
    letter-spacing: -.5px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 24px;
    color: $dark;
  }

  .card-text {
    color: $secondary;
    margin-bottom: 0;
  }

  .card-thumb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width: 100px;
    height: 100px;
    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }
  }

  .card-body {
    padding: 30px;
    position: relative;

    small {
      font-size: 12px;
      color: $secondary;
      display: block;
    }

    strong {
      font-weight: $font-weight-semibold;
      color: $dark;
    }
  }

  .form-label {
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    display: block;
  }

  .form-control {
    font-weight: $font-weight-medium;
    color: $dark;
    height: 40px;

    &::placeholder {
      font-weight: 400;
      color: rgba($secondary, .6);
      text-shadow: none;
    }

    &:focus {
      border-color: $primary;
    }
  }

  .btn {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $ff-secondary;
    letter-spacing: 1px;
    color: #fff;

    i {
      line-height: 1;
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .divider {
    text-transform: uppercase;
    font-family: $ff-secondary;
    letter-spacing: .5px;

    span {
      font-size: 10px;
      color: rgba($secondary, .75);
    }
  }

  .card-footer {
    background-color: transparent;
    text-align: center;
    padding: 0 30px 30px;
    color: $secondary;
    border-top-width: 0;
  }
}

.modal-sign {
  // width: 100%;
  border-width: 0;
  border-radius: 0;

  // @media (min-width: 400px) { width: 590px; }

  // @include media-breakpoint-up(sm) {
  //   border-radius: 10px;
  //   @include shadow-smooth($secondary);
  // }

  .card-header {
    background-color: transparent;
    border-bottom-width: 0;
    padding: 30px 30px 0;
  }

  .card-title {
    letter-spacing: -.5px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 24px;
    color: $dark;
  }

  .card-text {
    color: $secondary;
    margin-bottom: 0;
  }

  .card-thumb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width: 100px;
    height: 100px;
    border-radius: 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: inherit;
      object-fit: cover;
    }
  }

  .card-body {
    padding: 30px;
    position: relative;

    small {
      font-size: 12px;
      color: $secondary;
      display: block;
    }

    strong {
      font-weight: $font-weight-semibold;
      color: $dark;
    }
  }

  .form-label {
    font-weight: $font-weight-medium;
    font-size: $font-size-sm;
    display: block;
  }

  .form-control {
    font-weight: $font-weight-medium;
    color: $dark;
    height: 40px;

    &::placeholder {
      font-weight: 400;
      color: rgba($secondary, .6);
      text-shadow: none;
    }

    &:focus {
      border-color: $primary;
    }
  }

  .btn {
    display: flex;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $ff-secondary;
    letter-spacing: 1px;
    color: #fff;

    i {
      line-height: 1;
      font-size: 16px;
      margin-right: 5px;
    }
  }

  .divider {
    text-transform: uppercase;
    font-family: $ff-secondary;
    letter-spacing: .5px;

    span {
      font-size: 10px;
      color: rgba($secondary, .75);
    }
  }

  .card-footer {
    background-color: transparent;
    text-align: center;
    padding: 0 30px 30px;
    color: $secondary;
    border-top-width: 0;
  }
}

.btn-facebook {
  background-color: #395498;
  &:hover, &:focus { background-color: darken(#395498, 5%); }
}

.btn-google {
  background-color: #fd6584;
  &:hover, &:focus { background-color: darken(#fd6584, 5%); }
}

.auth-img {
  width: 100%;
  height: auto;
  object-fit: contain;
  mix-blend-mode: luminosity;
  opacity: .2;
}
// .auth-img {
//   width: 100%;
//   height: auto;
//   object-fit: contain;
//   position: relative;
// }


.card-auth {
  max-width: 420px;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;

  .card-title {
    font-size: 24px;
    color: $dark;
    margin-bottom: 15px;
  }

  .card-text { color: $secondary; }

  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    text-transform: uppercase;
    letter-spacing: .5px;
    font-size: 12px;
    font-family: $ff-secondary;
  }
}

.page-auth {
  .content .container {
    height: calc(100vh - 72px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .form-control {
    height: 40px;
  }
}
